export class Calculator {
  getWeeklyAmount(item) {
    if (item.frequency === 'Weekly') return Number(item.amount);
    if (item.frequency === 'Fortnightly') return Number(item.amount / 2);
    if (item.frequency === 'Monthly')
      return Number(((item.amount * 12) / 365) * 7);
    if (item.frequency === 'Quarterly')
      return Number(((item.amount * 4) / 365) * 7);
    if (item.frequency === '4-Monthly')
      return Number(((item.amount * 3) / 365) * 7);
    if (item.frequency === 'Half Yearly')
      return Number(((item.amount * 2) / 365) * 7);
    if (item.frequency === 'Yearly') return Number((item.amount / 365) * 7);
  }
  getFortnightlyAmount(item) {
    return this.getWeeklyAmount(item) * 2;
  }
  getMonthlyAmount(item) {
    return ((this.getWeeklyAmount(item) / 7) * 365) / 12;
  }
  getQuarterlyAmount(item) {
    return ((this.getWeeklyAmount(item) / 7) * 365) / 4;
  }
  getFourMonthlyAmount(item) {
    return ((this.getWeeklyAmount(item) / 7) * 365) / 3;
  }
  getHalfYearlyAmount(item) {
    return ((this.getWeeklyAmount(item) / 7) * 365) / 2;
  }
  getYearlyAmount(item) {
    return (this.getWeeklyAmount(item) / 7) * 365;
  }
  getItemAmountPerFrequency(frequency, item) {
    let amountPerBudgetFrequency = 0;
    switch (frequency) {
      case 'Weekly':
        amountPerBudgetFrequency = this.getWeeklyAmount(item);
        break;
      case 'Fortnightly':
        amountPerBudgetFrequency = this.getFortnightlyAmount(item);
        break;
      case 'Monthly':
        amountPerBudgetFrequency = this.getMonthlyAmount(item);
        break;
      case 'Quarterly':
        amountPerBudgetFrequency = this.getQuarterlyAmount(item);
        break;
      case '4-Monthly':
        amountPerBudgetFrequency = this.getFourMonthlyAmount(item);
        break;
      case 'Half Yearly':
        amountPerBudgetFrequency = this.getHalfYearlyAmount(item);
        break;
      case 'Yearly':
        amountPerBudgetFrequency = this.getYearlyAmount(item);
        break;
    }
    return amountPerBudgetFrequency;
  }
}
