import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Amplify, { Auth } from 'aws-amplify';
import '@aws-amplify/ui-vue';
import awsconfig from './aws-exports';

// Import Bootstrap Vue
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

// Configure Boostrap Vue
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
