<template>
  <div id="app">
    <b-container v-if="authState !== 'signedin'">
      <amplify-authenticator
        ><amplify-sign-in
          slot="sign-in"
          header-text="Welcome to Thrifty Pig, Please sign in to access your resources"
        ></amplify-sign-in
      ></amplify-authenticator>
    </b-container>
    <b-container v-if="authState === 'signedin' && user">
      <b-row>
        <b-col md="3">
          <div id="logo"></div>
        </b-col>
        <b-col md="6">
          <b-nav>
            <b-nav-item
              ><router-link class="primary-colour-text" to="/"
                >Home</router-link
              ></b-nav-item
            >
            <b-nav-item
              ><router-link class="primary-colour-text" to="/about"
                >About</router-link
              ></b-nav-item
            >

            <b-nav-item>
              <router-link class="primary-colour-text" to="/bank-account"
                >Bank Accounts</router-link
              ></b-nav-item
            >
            <b-nav-item
              ><router-link class="primary-colour-text" to="/income"
                >Income</router-link
              ></b-nav-item
            >
            <b-nav-item
              ><router-link class="primary-colour-text" to="/expense"
                >Expense</router-link
              ></b-nav-item
            ></b-nav
          >
        </b-col>
        <b-col md="3">
          <amplify-sign-out></amplify-sign-out>
        </b-col>
      </b-row>
      <router-view />
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapGetters(['user', 'authState']),
  },
  async created() {
    return onAuthUIStateChange(async (nextAuthState, authData) => {
      await this.$store.dispatch('setUserData', authData);
      await this.$store.dispatch('setAuthState', nextAuthState);
      if (this.authState === 'signedin' && this.$route.query.redirect)
        this.$router.push({ path: this.$route.query.redirect });
    });
  },
  async beforeDestroy() {
    return onAuthUIStateChange(async nextAuthState => {
      await this.$store.dispatch('unsetUserData');
      await this.$store.dispatch('setAuthState', nextAuthState);
    });
  },

  methods: {
    ...mapActions[
      ('setUserData', 'setAuthState', 'unsetUserData', 'unsetAuthState')
    ],
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.nav {
  padding-top: 30px;
}

#logo {
  min-height: 60px;
  max-height: 100%;
  background-image: url('./assets/logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
amplify-sign-out {
  background-color: #42b983;
}

.nav a {
  font-weight: bold;
  color: #f2b1af;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.primary-colour-text {
  color: #f2b1af;
}

.primary-colour-background {
  background-color: #f2b1af;
}

.button:hover {
  background-color: #ff7359 !important;
  opacity: 1 !important;
}

:root {
  --amplify-primary-color: #f2b1af;
  --amplify-primary---amplify-primary-tint: #ff7359;
}
</style>
