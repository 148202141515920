import Vue from 'vue';
import Vuex from 'vuex';
import expense from '@/store/modules/expense.js';
import income from '@/store/modules/income.js';
import bankAccount from '@/store/modules/bankAccount.js';
import transaction from '@/store/modules/transaction.js';
import auth from '@/store/modules/auth.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    bankAccount,
    expense,
    income,
    transaction,
  },
});
