import { API, graphqlOperation } from 'aws-amplify';
import { listIncomes } from '@/graphql/queries';
import { createIncome, updateIncome } from '@/graphql/mutations';
import { Calculator } from '@/classes/Calculator';

const state = {
  incomeList: [],
};

const getters = {
  incomeList: state => {
    return state.incomeList;
  },
};

const mutations = {
  updateIncomeList: (state, payload) => {
    state.incomeList = payload;
  },
};

const actions = {
  updateIncomeList: async (context, payload = { frequency: 'Weekly' }) => {
    // call a fetch to graphQL to get the list
    const refreshedIncomeList = [];
    // Get all income list items to display on page load
    let { data } = await API.graphql(graphqlOperation(listIncomes));

    data.listIncomes.items.forEach(item => {
      refreshedIncomeList.push(item);
    });
    for (const item of refreshedIncomeList) {
      item.amountPerBudgetFrequency = new Calculator().getItemAmountPerFrequency(
        payload.frequency,
        item,
      );
    }
    // update the state with the refreshed list
    await context.commit('updateIncomeList', refreshedIncomeList);
  },
  updateIncome: async (context, payload) => {
    if (typeof payload.amount === 'string')
      payload.amount = Number(payload.amount.replace(/[^0-9.-]+/g, ''));
    API.graphql(
      graphqlOperation(updateIncome, {
        input: {
          id: payload.id,
          description: payload.description,
          amount: payload.amount,
          frequency: payload.frequency,
          startPaymentDate: payload.startPaymentDate,
          bankAccountID: payload.bankAccountID,
        },
      }),
    )
      .then(async () => {
        await context.dispatch('updateIncomeList');
      })
      .catch(error => console.log(error));
  },
  addIncome: async (context, payload) => {
    console.log(payload);
    payload.amount = Number(payload.amount.replace(/[^0-9.-]+/g, ''));
    API.graphql(
      graphqlOperation(createIncome, {
        input: payload,
      }),
    );
    await context.dispatch('updateIncomeList');
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
