import { API, graphqlOperation } from 'aws-amplify';
import { listExpenses } from '@/graphql/queries';
import { createExpense, updateExpense } from '@/graphql/mutations';
//import * as subscriptions from '@/graphql/subscriptions';

import { Calculator } from '@/classes/Calculator';

const state = {
  expenseList: [],
};

const getters = {
  expenseList: state => {
    return state.expenseList;
  },
};

const mutations = {
  updateExpenseList: (state, payload) => {
    state.expenseList = payload;
  },
};

const actions = {
  updateExpenseList: async (context, payload = { frequency: 'Weekly' }) => {
    // call a fetch to graphQL to get the list
    const refreshedExpenseList = [];
    // Get all expense list items to display on page load
    let { data } = await API.graphql(graphqlOperation(listExpenses));

    data.listExpenses.items.forEach(item => {
      refreshedExpenseList.push(item);
    });
    for (const item of refreshedExpenseList) {
      item.amountPerBudgetFrequency = new Calculator().getItemAmountPerFrequency(
        payload.frequency,
        item,
      );
    }
    // update the state with the refreshed list
    await context.commit('updateExpenseList', refreshedExpenseList);
  },
  updateExpense: async (context, payload) => {
    if (typeof payload.amount === 'string')
      payload.amount = Number(payload.amount.replace(/[^0-9.-]+/g, ''));
    API.graphql(
      graphqlOperation(updateExpense, {
        input: {
          id: payload.id,
          description: payload.description,
          amount: payload.amount,
          frequency: payload.frequency,
          startPaymentDate: payload.startPaymentDate,
          bankAccountID: payload.bankAccountID,
        },
      }),
    )
      .then(async () => {
        await context.dispatch('updateExpenseList');
      })
      .catch(error => console.log(error));
  },
  addExpense: async (context, payload) => {
    console.log(payload);
    payload.amount = Number(payload.amount.replace(/[^0-9.-]+/g, ''));
    API.graphql(
      graphqlOperation(createExpense, {
        input: payload,
      }),
    );
    await context.dispatch('updateExpenseList');
  },
  deleteExpense: (context, payload) => {
    console.log(payload);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
