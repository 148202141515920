const state = {
  transactionList: [],
};

const getters = {
  transactionList: state => {
    return state.transactionList;
  },
};

const mutations = {
  updateTransactionList: (state, payload) => {
    state.transactionList = payload;
  },
};

const actions = {
  updateTransactionList: context => {
    // TODO - call a fetch to graphQL to get the list
    const refreshedTransactionList = [
      {
        id: 1,
        description: 'a refreshed transaction',
        amount: 100,
        bankAccountId: 1,
        expenseId: null,
        incomeId: 1,
      },
    ];
    // TODO - update the state with the refreshed list
    context.commit('updateTransactionList', refreshedTransactionList);
  },
  updateTransaction: (context, payload) => {
    // TODO - call a post to graphQL to update the transaction
    console.log(payload);
    context.actions.dispatch('updateTransactionList');
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
