import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Home.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/bank-account',
    name: 'BankAccountList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/BankAccountList.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/expense',
    name: 'Expense',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/Expense.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/income',
    name: 'Income',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/Income.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  const user = store.getters['user'];
  const authState = store.getters['authState'];
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!user || authState !== 'signedin') {
      next({
        path: '/',
        query: {
          redirect: to.fullPath,
        },
      });
    }
  }
  next();
});

export default router;
