const state = {
  user: null,
  authState: null,
};
const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData;
    //localStorage.setItem('user', JSON.stringify(userData));
  },
  UNSET_USER_DATA(state) {
    state.user = null;
    //localStorage.removeItem('user');
  },
  SET_AUTH_STATE(state, authState) {
    state.authState = authState;
  },
  UNSET_AUTH_STATE(state) {
    state.authState = null;
  },
};
const actions = {
  setUserData({ commit }, data) {
    commit('SET_USER_DATA', data);
  },
  unsetUserData({ commit }) {
    commit('UNSET_USER_DATA');
  },
  setAuthState({ commit }, data) {
    commit('SET_AUTH_STATE', data);
  },
  unsetAuthState({ commit }) {
    commit('UNSET_AUTH_STATE');
  },
};
const getters = {
  loggedIn: state => {
    return !!state.user;
  },
  user: state => {
    return state.user;
  },
  authState: state => {
    return state.authState;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
