import { API } from 'aws-amplify';
import { createBankAccount } from '@/graphql/mutations';
import { listBankAccounts } from '@/graphql/queries';
//import { v4 as UUID } from 'uuid';

const state = {
  bankAccountList: [],
};

const getters = {
  bankAccountList: state => {
    return state.bankAccountList;
  },
};

const mutations = {
  updateBankAccountList: (state, payload) => {
    state.bankAccountList = payload;
  },
};

const actions = {
  updateBankAccountList: async context => {
    // TODO - call a fetch to graphQL to get the list
    const list = await API.graphql({
      query: listBankAccounts,
    });
    console.log(list);
    const refreshedBankAccountList = list.data.listBankAccounts.items;
    // TODO - update the state with the refreshed list
    context.commit('updateBankAccountList', refreshedBankAccountList);
  },
  updateBankAccount: (context, payload) => {
    // TODO - call a post to graphQL to update the bank account
    console.log(payload);
    context.actions.dispatch('updateBankAccountList');
  },
  addBankAccount: async (context, payload) => {
    // payload.id = UUID();

    console.log(payload);
    console.log(
      await API.graphql({
        query: createBankAccount,
        variables: { input: payload },
      }),
    );
    context.dispatch('updateBankAccountList');
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
