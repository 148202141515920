/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:532db36a-e0e7-4e75-99d2-e0cd22347266",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_IzzR9ghfH",
    "aws_user_pools_web_client_id": "2qi7c0h19ugncb5dcnfsagj1mh",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://x7cftz453je2fn4psrzivuvury.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
